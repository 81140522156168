.customDropDownLayout {
  padding: 0.435rem;
  border: 0.063rem solid #ebebeb;
  font: normal normal normal 600 0.8rem/0.875rem 'Open Sans';
  border-radius: 0.25rem;
  box-sizing: border-box;
  resize: vertical;
  text-align: start;
}
.input-dropdown-color {
  color: #7b7b7b;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 35px;
}
.display-footer {
  font: normal normal normal 13px Open Sans;
  color: #a7a7a7;
}
.clear-button {
  color: #a7a7a7;
  text-decoration: underline;
  font: normal normal bold 13px Open Sans;
  cursor: pointer;
}
.search-button-width {
  width: 0.5rem;
}
.list-maincontainer {
  height: 100%;
  width: 100%;
  margin-top: 1.25rem;
  margin-bottom: 0.3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.search-container {
  display: flex;
  align-items: center;
  border: 1px solid #dde5f3;
  padding: 0.25rem 0.8rem;
  border-radius: 4px;
  width: 100%;
  background-color: white;
  margin-bottom: 15px;
}

.search-button {
  font-size: 0.8rem;
  color: #707070;
}

.input-style {
  border: none;
  outline: 0px;
  font: normal normal normal 0.8rem Open Sans;
}

.list {
  height: 70vh;
  overflow-y: scroll;
}

.group-title {
  display: inline-block;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  padding: 2px 10px;
  margin-left: 20px;
}

.inner-margin {
  margin: 10px 20px;
}

.grouped-row {
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}

.item-row,
.item-row-first {
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: background-color 0.3s ease;
}

.alert-circle-row {
  display: flex;
  align-items: center;
}

.item-row-top,
.item-row-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin: 0px 15px;
  color: #000; /* Default text color, ensures text is black when not selected */
}

.item-row.selected {
  background-color: rgba(
    43,
    45,
    47,
    1
  ); /* Translucent black for selected item */
}

.rowWrapper:hover {
  background-color: rgba(43, 45, 47, 1) !important;
  color: white !important;
}

/* Directly specifying text color change for child elements */
.item-row.selected .item-row-top,
.item-row.select .item-row,
.item-row.selected .item-row-bottom,
.item-row.selected .item-row-top b,
.item-row.selected .item-row-bottom i {
  color: white !important; /* Explicitly set text color to white for selected rows */
}

.item-row:hover .item-row-top,
.item-row:hover .item-row,
.item-row:hover .item-row-bottom,
.item-row:hover .item-row-top b,
.item-row:hover .item-row-bottom i {
  color: white !important; /* Explicitly set text color to white for selected rows */
}

.item-row.selected::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 5%;
  height: 100%;
  background-color: var(--group-title-color);
}

.item-row:hover::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 5%;
  height: 100%;
  background-color: var(--group-title-color);
}

.item-row:hover {
  color: white !important;
  background-color: rgba(43, 45, 47, 1) !important;
}
.truck-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 260px;
}
.sort-container {
  width: 70%;
}

.truck-detail {
  color: #0053d8;
  font: normal normal bold 14px/19px Open Sans;
  text-decoration: underline;
  text-align: center;
  align-self: center;
  margin-left: 8px;
  text-wrap: nowrap;
  cursor: pointer;
}
.disabled-truck-detail {
  opacity: 0.8;
  text-align: center;
  align-self: center;
  text-wrap: nowrap;
  margin-left: 8px;
  font: normal normal bold 14px/19px Open Sans;
}
.Truck-arrow {
  color: #0053d8;
  text-decoration: none;
  margin-top: 5px;
  margin-left: 5px;
  font: normal normal bold 14px/19px Open Sans;
}

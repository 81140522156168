.subheader {
  // background-color: #fff;
  justify-content: flex-end;
  padding: 10px 0px 0px 0px;
  align-items: center;
}

.filter-div {
  display: flex;
}

.mm-search-input {
  border: 1px solid #ebebeb !important;
  width: 20rem;
  border-radius: 25px !important;
  padding-left: 10px !important;
  color: #333;
  font-size: 15px;
  padding-right: 30px !important;
  font-size: 1rem;
  height: 2.8rem;
}

.mm-date-input {
  margin-left: 3.2rem;

  height: 2.8rem;
  border: 1px solid #ebebeb !important;
  width: 13.4rem;
  border-radius: 6px !important;
  color: #333;
  font-size: 15px;
  padding: 2px !important;
}

.mm-search-input::placeholder {
  color: #707070;
  font-size: 1rem;
}

.mm-search-input:focus {
  border: 1px solid #ebebeb;
  outline: none;
}
.mm-search-icon {
  margin-left: -35px;
  height: 27px;
  cursor: pointer;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-breakdown-body {
  background-color: white;
  height: max-content;
  margin: 0;
  border-radius: 0.25rem;
  margin-top: -0.9rem;
  font-size: 0.8rem;
}

.material-breakdown-body-active {
  background-color: white;
  height: max-content;
  margin: 0;
  /* padding: 0rem 0.7rem; */
  border-radius: 0.8rem;
  margin-top: -0.9rem;
  font-size: 0.8rem;
}

.Toastify .Toastify__toast-container {
  z-index: 99999999;
}

.popup-field {
  font: normal normal normal 0.8rem/1rem Open Sans;
  margin-top: 0.625rem;
  color: #707070;
}

.popup-field-value {
  font: normal normal 600 0.8rem/1rem Open Sans;
  margin-top: 0.625rem;
  color: #000000;
}

.flyout-card {
  width: 0.7rem;
  height: 1.3rem;
  opacity: 1;
  margin-left: 0.93rem;
  margin-top: 0.76rem;
  margin-right: 0.34375rem;
}

.flyout {
  width: 13.125rem;
  height: 17.5rem;
  background: #ffffff;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(0, 0, 0, 0.29);
  opacity: 1;
  margin-left: 3rem;
  margin-top: 3rem;
  position: absolute;
  border-radius: 1rem;
  z-index: 1;
}

.m-body {
  /* font-size: 1.4rem; */
  position: absolute;
  line-height: 2.5rem;
  font-weight: 400;
  min-width: 31.313rem;
  overflow-x: hidden;
  color: var(--e-global-color-dark-blue);
  scroll-behavior: smooth;
  /* font-size: 75%; */
  /* height: 100%; */
}

.mm-top_heading h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  text-transform: capitalize;
  color: var(--e-global-before-rightbar-text-color);
}

.m-body-right {
  font: normal normal 0.8rem Open Sans;
  position: absolute;
  right: 1rem;
  line-height: 1.5;
  font-weight: 400;
  color: var(--e-global-color-dark-blue);
}

.modal-container-heading {
  border-radius: 0.625rem;
  min-width: 40rem;
  min-height: 49.375rem;
  border: 0.1rem solid black;
  margin-top: 1.25rem;
  margin-bottom: 8.25rem;
  background-color: white;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 0.625rem;
  min-height: calc(100vh - 12rem);
  margin-top: 5rem;
  padding: 0 0rem 0.5rem 0rem;
  background-color: white;
  position: fixed;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.point-form {
  max-height: calc(100vh - 19rem);
  min-height: calc(100vh - 19rem);
}

.button-custom {
  width: 6.7rem;
  height: 2rem;
  font: normal normal bold Open Sans;
  border: 1px solid black !important;
  border-radius: 4;
  font-size: 0.8rem;
}

.custom-table-active {
  width: 30.05rem;
  border-collapse: collapse;
}

.custom-table-active th,
.custom-table-active td {
  width: 100%;
  text-align: left;
  border: none;
  padding: 0.5rem;
}

.custom-table-active table {
  width: 100%;
}

.custom-table-active thead tr {
  font: normal normal 600 0.8rem Open Sans;
  margin-bottom: 0.563rem;
  height: 3rem;
}

.custom-table-active tbody tr {
  height: 3rem;
  font: normal normal normal 0.8rem Open Sans;
}

.custom-table-active thead th {
  text-align: left;
  border: none;
  padding: 0.4rem;
  font-size: 0.9rem;
}

.custom-table-active td {
  text-align: left;
  border: none;
  padding: 0.4rem;
}

.custom-table-active thead th:nth-child(1),
.custom-table-active tbody tr td:nth-child(1) {
  width: 0.15rem;
  /* Adjust the first column width as needed */
}

.custom-table-active thead th:nth-child(2),
.custom-table-active tbody tr td:nth-child(2) {
  width: 28%;

  /* Adjust the second column width as needed */
}

.custom-table-active thead th:nth-child(3) {
  width: 22%;
}

.custom-table-active tbody tr td:nth-child(3) {
  width: 23.5%;
}

.custom-table-active thead th:nth-child(4) {
  width: 25%;
}

.custom-table-active tbody tr td:nth-child(4) {
  width: 26%;
}

.custom-table-active thead th:nth-child(5),
.custom-table-active tbody tr td:nth-child(5) {
  width: 25%;
  /* Adjust the fifth column width as needed */
}

.custom-table-active thead th:nth-child(6),
.custom-table-active tbody tr td:nth-child(6) {
  width: 0.225rem;
  /* Adjust the first column width as needed */
}

.custom-table-inactive {
  width: 30.05rem;
  border-collapse: collapse;
}

.custom-table-inactive thead th {
  text-align: left;
  border: none;
  padding: 0.4rem;
  font-size: 0.9rem;
}

.custom-table-inactive td {
  text-align: left;
  border: none;
  padding: 0.4rem;
}

.custom-table-inactive table {
  width: 100%;
}

.custom-table-inactive thead tr {
  font: normal normal 600 0.8rem Open Sans;
  margin-bottom: 0.563rem;
  height: 3rem;
}

.custom-table-inactive tbody tr {
  height: 3rem;
  font: normal normal normal 0.8rem Open Sans;
}

.custom-table-inactive th,
.custom-table-inactive td {
  text-align: left;
  border: none;
  padding: 0.4rem;
}

.custom-table-inactive thead th:nth-child(1),
.custom-table-inactive div tbody tr td:nth-child(1) {
  width: 0.15rem;
  /* Adjust the first column width as needed */
}

.custom-table-inactive thead th:nth-child(2) {
  width: 40%;
}

.custom-table-inactive tbody tr td:nth-child(2) {
  width: 40.5%;
}

.custom-table-inactive thead th:nth-child(3) {
  width: 30%;
}

.custom-table-inactive tbody tr td:nth-child(3) {
  width: 31%;
}

.custom-table-inactive thead th:nth-child(4) {
  width: 30%;
}

.custom-table-inactive tbody tr td:nth-child(4) {
  width: 31%;
}

.custom-table-inactive thead th:nth-child(5),
.custom-table-inactive div tbody tr td:nth-child(5) {
  width: 0.225rem;
  /* Adjust the first column width as needed */
}

.custom-table-truck-loads {
  width: 30.05rem;
  border-collapse: collapse;
}

.custom-table-truck-loads th {
  width: 100%;
  text-align: left;
  border: none;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.custom-table-truck-loads td {
  width: 100%;
  text-align: left;
  border: none;
  padding: 0.5rem;
}

.custom-table-truck-loads table {
  width: 100%;
}

.custom-table-truck-loads thead tr {
  font: normal normal 600 0.8rem Open Sans;
  margin-bottom: 0.563rem;
  height: 3rem;
}

.custom-table-truck-loads tbody tr {
  height: 3rem;
  font: normal normal normal 0.8rem Open Sans;
}

.custom-table-truck-loads th,
.custom-table-truck-loads td {
  text-align: left;
  border: none;
  padding: 0.4rem;
}

.custom-table-truck-loads thead th:nth-child(1),
.custom-table-truck-loads .scroll-div tbody tr td:nth-child(1) {
  width: 0.15rem;
  /* Adjust the first column width as needed */
}

.custom-table-truck-loads thead th:nth-child(2) {
  width: 28%;
}

.custom-table-truck-loads .scroll-div tbody tr td:nth-child(2) {
  width: 28%;
}

.custom-table-truck-loads thead th:nth-child(3) {
  width: 22%;
}

.custom-table-truck-loads .scroll-div tbody tr td:nth-child(3) {
  width: 23.5%;
}

.custom-table-truck-loads thead th:nth-child(4) {
  width: 25%;
}

.custom-table-truck-loads .scroll-div tbody tr td:nth-child(4) {
  width: 25.5%;
}

.custom-table-truck-loads thead th:nth-child(5),
.custom-table-truck-loads d.scroll-diviv tbody tr td:nth-child(5) {
  width: 25%;
  /* Adjust the fifth column width as needed */
}

.custom-table-truck-loads thead th:nth-child(6),
.custom-table-truck-loads .scroll-div tbody tr td:nth-child(6) {
  width: 0.225rem;
  /* Adjust the first column width as needed */
}

.live-active {
  background-color: #2b2d2f;
  color: white !important;
}

.live-inactive {
  background-color: white;
  color: black;
}

.assets-button {
  font: normal normal normal 0.8rem/1.125rem Open Sans;
  color: #707070;
}

.scroll-div::-webkit-scrollbar {
  display: none;
}

.category-filter-dropdown {
  font: normal normal normal 0.8rem/0.875rem 'Open Sans';
  margin-top: 0.4rem;
  margin-left: 0.5rem;
  color: #2b2d2f;
  white-space: nowrap;
}

.mm-dot-1 {
  height: 0.55rem;
  width: 0.55rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
}

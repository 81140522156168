.table_manage_user {
  font-size: 13px;
  th {
    padding: 8px;
    padding-bottom: 16px;
    vertical-align: middle;
    border-width: 0.5px 0px;
    border-color: #707070;
  }
  td {
    padding: 8px;
    vertical-align: middle;
    border-width: 0.5px 0px 0px 0px;
    border-color: #ddd;
  }
  .selected {
    td {
      padding: 8px;
      vertical-align: middle;
      border-width: 0.5px 0px;
      border-color: #3880f5;
      background-color: #f2f7ff;
    }
  }
  overflow-y: scroll;
  max-height: 70vh;
}
.users_header {
  position: sticky;
  top: 0;
}
.prestart_header {
  position: sticky;
  top: 0;
  border-width: 0.5px 0px;
  border-color: #707070;
}

.table_manage_user::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}
.table_manage_user::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: content-box;
}
.table_manage_user table tr td .table_text_blue {
  font-weight: 700;
}
tbody > tr:hover {
  background-color: #f8fbff;
  cursor: pointer;
}

.table_manage_user table thead th:first-child,
.table_manage_user table tbody td:first-child {
  width: 1%;
}

.table_manage_user table thead th:nth-child(2),
.table_manage_user table tbody td:nth-child(2) {
  width: 10%;
}

.table_manage_user table thead th:nth-child(3),
.table_manage_user table tbody td:nth-child(3) {
  width: 7%;
}

.table_manage_user table thead th:nth-child(4),
.table_manage_user table tbody td:nth-child(4) {
  width: 7%;
}

.table_manage_user table thead th:nth-child(5),
.table_manage_user table tbody td:nth-child(5) {
  width: 15%;
}

.table_manage_user table thead th:nth-child(6),
.table_manage_user table tbody td:nth-child(6) {
  width: 16%;
}

.table_manage_user table thead th:nth-child(7),
.table_manage_user table tbody td:nth-child(7) {
  width: 16%;
}
.table_manage_user table thead th:nth-child(8),
.table_manage_user table tbody td:nth-child(8) {
  width: 8%;
}

.table_manage_user table thead th:nth-child(10),
.table_manage_user table tbody td:nth-child(10) {
  width: 2%;
}

.table_manage_user .all_popup {
  width: 100%;
  background-color: transparent;
}
.table_manage_user .all_popup .add_new_user_box {
  position: absolute;
}
.table_text_blue {
  color: #2596be;
  cursor: pointer;
}

.image-click {
  cursor: pointer;
}
.manage-user-button-container {
  padding: 1rem 15px 1rem 0;
  display: flex;
  justify-content: space-between;
}
.buttons-container {
  display: flex;
  gap: 0.7rem;
  padding-top: 10px;
}

div.modal-position {
  position: fixed !important;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 600px;
  font-size: 14px;

  div.modal-content {
    margin-top: 15vh;
    height: 83vh !important;
    overflow: auto;
  }

  div.modal-content > div.modal-header {
    border-bottom: none;
  }
}
.add-user-input > input:focus {
  outline: none;
  border-color: #0f614e;
}

.field-container {
  margin-left: 25px;
  font-size: 13px;
}
.add-user-input > input {
  border: 1px solid #e1e8f4;
  border-radius: 4px;
  padding: 7px;
}

.no-focus-border:focus {
  outline: none;
}

.hover-card {
  background-color: white;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.2),
    0 4px 6px -4px rgb(0 0 0 / 0.2);
  position: absolute;
  width: 100%;
  z-index: 9;
}
.dropdown-position {
  left: 0;
  top: 37px;
}

.calendar-position {
  top: 40px;
}

.narrow-padding {
  padding: 5px;
}
.option-row {
  display: flex;
  gap: 4px;
  cursor: pointer;
  padding: 0 7px;
}
.option-row:hover {
  background-color: #e1e8f4;
}
.option-row > input,
.option-row > label {
  cursor: pointer;
  padding: 4px 7px;
}

.add-user-input {
  flex: 2;
}

.flex-one {
  padding: 7px;
  flex: 0.7;
  display: flex;
}
.switch-style {
  height: 100px;
}
.flex-two {
  flex: 2;
  padding: 7px;

  border: 1px solid #e1e8f4;
}
.switch-container {
  flex: 2;
}
.status-container {
  flex: 2;
}

// styling disbled input
input:disabled {
  background-color: #e1e4ea;
  color: #677789;
  padding: 7px;
}
.field-container > select:disabled {
  background-color: #e1e4ea;
  color: #677789;
}

div.modal-backgrop-style {
  background-color: greenyellow;
  display: none;
}

.dropdown-img {
  height: 11px;
  padding-right: 9px;
}

.dropdown-text {
  font-size: 13px;
}
.user-detail-text {
  font-size: 13px;
  color: #1a1a1a;
  font-weight: 600;
}
.user-label {
  width: 165px;
  font-size: 13px;
  padding: 5px 0px 5px 30px;
}
.user-detail-body {
  margin-top: -30px;
}

.button-press-change:hover {
  box-shadow: inset 0 0 3px black;
}
.button-press-change:active {
  box-shadow: inset 0 0 5px black;
}
.button-pressed {
  box-shadow: inset 0 0 5px black;
}

.fixed-width {
  min-width: 20px;
  min-height: 20px;
}

.status-toggle {
  width: 90px;
  font-size: 13px;
}

.small-btn-close {
  height: 5px !important;
  width: 5px !important;
  margin-left: 30px !important;
  margin-right: 20px !important;
}

.width-310px {
  width: 310px;
}

.rdp-day_today:not(.rdp-day_selected) {
  font-weight: bold !important;
  color: #186fd9 !important;
}

.pdf-view {
  background-color: #fff;
  padding: 30px;
  width: 800px;
  // width: 620px;
  margin: 20px auto;
  font-size: 12px;
  max-height: 95vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px;
}

.pdf-view::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}
.pdf-view::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: content-box;
}

.pdf-header {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.table-head {
  border-bottom: 1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
}

.key {
  font-weight: 600;
}

.job-report-heading {
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.table-lower td {
  border: none;
}

.water-text-report {
  font-weight: 600;
  font-size: 12px;
}

.gusts {
  color: #93b1f5;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.report-logo {
  height: 50px;
}

#pdf-table td {
  padding-top: 4px;
  padding-bottom: 4px;
}
#pdf-table td:first-child {
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
}
#pdf-table th {
  padding-top: 4px;
  padding-bottom: 4px;
  vertical-align: top;
}

//individual job report
.job-info {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 10px;
  width: 90%;
  margin: auto;
}

.key {
  font-weight: 800;
  font-size: 11px;
}

.tableFixHead {
  overflow: auto;
  height: 490px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.job-report-info-header {
  margin-bottom: 10px;
  text-decoration: underline;
  font-size: 12px;
  text-align: left;
  margin-top: 25px;
  font-weight: 700;
}

.bottom {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 70%;
  width: 90%;
  margin: auto;
}

.bottom td {
  padding: 3px;
  font-weight: normal;
}

.job-info td {
  padding: 2px;
}

td .key {
  padding: 5px;
  font-weight: bold;
}

//downlod div
.download-icon-report {
  display: flex;
  justify-content: flex-end;
  margin-top: 2%;
  margin-right: 2%;
}
.pdf-modal-content {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  background-color: transparent;
  width: 100%;
  margin-right: -2%; // to keep it in the middle despite download-icon-report
}
.modal-view-report {
  flex: 1;
  justify-content: center;
  flex-direction: row;
  z-index: 999;
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
  display: flex;
}

.summary-card {
  font-weight: bold;
  padding: 15px 30px 15px 20px;
  border-radius: 10px;
  background: #dbdfe6 0% 0% no-repeat padding-box;
  min-width: 160px;
}
.summary-card div:last-child {
  font-weight: 800;
  margin-top: 2px;
}

.truck-details {
  background-color: #f1f4f7;
  margin: 35px -30px;
  padding: 10px 30px;
}

.ref-no-td {
  min-width: 60px;
}
.water-td {
  max-width: 100px;
}
.duration-td {
  width: 130px;
}

.job-type-header {
  font-weight: bold;
  padding: 5px 25px;
  margin: 10px 0;
  border-radius: 6px;
  background: #dbdfe6 0% 0% no-repeat padding-box;
  width: 125px;
}

.litrage-container {
  width: 290px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 40px;
  margin: -4px;
}
.litrage {
  width: 80px;
  align-self: center;
}
.zone-breakdown td {
  vertical-align: middle;
}

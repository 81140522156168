.container-with-side-border {
  border-right: 1px solid #e2e2e2;
  padding-right: 30px;
  text-align: justify;
}
.matching-padding {
  padding-right: 30px; //must match padding above so the bin details title is equidistant from the center line
}
.details-container {
  margin: 0 30px 0 0;
}

.bin_label_with_line-title {
  font: normal normal bold 14px/17px Open Sans;
  color: #000000;
}

.bin_label_with_line-border {
  height: 1px;
  margin-bottom: 6px;
  margin-left: 6px;
  background-color: #e2e2e2;
}

.bin_key_value-title {
  color: #6a6a6a;
  width: 140px; // must be wide enough for the text Engine Hours (Lifetime): to fit (Truck HIstory details card)
  text-align: left;
  margin-right: 10px;
  margin-bottom: 8px;
  font: normal normal normal 14px/20px Open Sans;
}

.bin_key_value-answer {
  color: #000000;
  margin-bottom: 8px;
  font: normal normal 600 14px/19px Open Sans;
}

.small-text {
  font-size: 12px;
}

.track-bin-container {
  max-height: 80vh;
  display: flex;
  justify-content: center;
}

.legend-button {
  letter-spacing: 0px;
  color: #0053d8;
  opacity: 1;
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
  font-size: 13px;
  font-weight: bold;
}
.legend-button:hover {
  background: #e6f0e7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: black;
}
.truck-text {
  font: normal normal bold 18px/24px Open Sans;
  color: #000000;
}
.truck-number {
  font: italic normal normal 18px/24px Open Sans;
  color: #6a6a6a;
}
.job-cycle {
  width: 400px;
}

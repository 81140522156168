.landing-container {
  background: linear-gradient(180deg, #fafaf2 0%, #eef2e7 100%);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.home-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 20px;
  margin-bottom: 50px;
}

.department-container {
  display: grid;
  grid-template-columns: repeat(4, 250px);
  grid-template-rows: repeat(3, 170px);
  gap: 30px;
}

.department-item {
  grid-column: span 1;
  grid-row: span 1;
  background: #fcfdf8;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.department-icon {
  max-height: 80%;
  width: auto;
}

.department-item:nth-child(1) {
  grid-column: span 2;
  grid-row: span 2;
  flex-direction: row-reverse;
  align-items: start;
  padding: 40px 20px 20px 30px;
  .department-name {
    align-self: flex-end;
  }
  .department-icon {
    max-height: 100%;
    width: auto;
  }
}

.department-item:nth-child(6) {
  grid-column: span 2;
  grid-row: span 1;
  flex-direction: row-reverse;
  align-items: start;
  padding: 20px 30px;
  .department-name {
    align-self: flex-end;
  }
  .department-icon {
    max-height: 100%;
    width: auto;
  }
}

.department-item:hover {
  background: #f0f2e4;
}

.department-item:active {
  background: #dae2c2;
}

.department-disabled {
  cursor: not-allowed;
}

.department-name {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 1300px) {
  .department-container {
    grid-template-columns: repeat(4, 200px);
    grid-template-rows: repeat(3, 140px);
    gap: 20px;
  }
  .department-item:nth-child(1) {
    .department-icon {
      max-height: 90%;
    }
  }
  .department-item:nth-child(6) {
    padding: 20px 30px;
  }
  .department-name {
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .department-container {
    grid-template-columns: repeat(3, 200px);
    grid-template-rows: repeat(3, 140px);
  }
  .department-item:nth-child(6) {
    grid-column: span 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .department-name {
      align-self: center;
    }
    .department-icon {
      max-height: 80%;
    }
  }
}

@media screen and (max-width: 700px) {
  .department-container {
    grid-template-columns: repeat(2, 200px);
    grid-template-rows: repeat(2, 140px);
  }
}

@media screen and (max-width: 500px) {
  .department-container {
    grid-template-columns: repeat(1, 250px);
    grid-template-rows: repeat(1, 170px);
  }
  .department-item:nth-child(1) {
    grid-column: span 1;
    grid-row: span 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    .department-name {
      align-self: center;
    }
    .department-icon {
      max-height: 80%;
    }
  }
}

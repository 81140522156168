@import './screens/Login.scss';
@import './screens/dashboard.scss';

@import './components/CustomButton.scss';
@import './components/BasicButton.scss';
@import './components/SmallAlertModal.scss';
@import './components/header.scss';
@import './components/StatusDot.scss';
@import './components/zoneTable.scss';
@import './components/tabs.scss';
@import './components/truckInfo.scss';
@import './components/ManageZoneModal.scss';
@import './components/Modal.scss';
@import './components/showSideMenu.scss';
@import './components/profileModal.scss';
@import './components/changePasswordModal.scss';
@import './components/subheader.scss';
@import './components/filtermodal.scss';
@import './components/manageUser.scss';
@import './components/pdfmodal.scss';
@import './components/Toggle.scss';
@import './components/floatingInput.scss';
@import './components/Sidebar.scss';
@import './components/DownloadModal.scss';
@import './components/waterTower.scss';
@import '../../../pages/landingPage/landingPage.scss';
@import './components/popUpModal.scss';

body,
.app {
  padding: 0;
  margin: 0;
  flex: 1;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

#float-label input:focus {
  border: 1px solid #0f614e !important;
}

#float-label {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 5px;
}

#float-label input {
  width: 100%;
  padding: 14px 10px 5px 10px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-size: 13px;
  align-self: center;
}
// styling disbled input
#float-label input:disabled {
  background-color: #e1e4ea;
  color: #677789;
}

#float-label input:-webkit-autofill {
  animation-name: onAutoFillStart;
  animation-duration: 0.01s;
}

@keyframes onAutoFillStart {
  0% {
    border: 1px solid #ddd;
  }
  100% {
    border: 1px solid #ddd;
  }
}

#float-label label {
  font-size: 14px;

  //padding: 0 12px;
  color: #999;
  pointer-events: none;
  position: absolute;
  padding-left: 10px;
  margin-top: -22px;
  transform: translate(0px, 20px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
}

#float-label:focus-within label {
  transform: translate(5px, 15px) scale(0.7);
}

#float-label .Active {
  transform: translate(5px, 15px) scale(0.7);
}

.table-head {
  border: 10px solid black !important;
}
.error-msg {
  color: #be1e10;
  font-size: 10px;
  font-weight: 600;
  margin: 2px 0 15px 0;
  width: 80%;
  margin-left: 20px;
}

.error-msg-manage-operator {
  color: #be1e10;
  font-size: 10px;
  font-weight: 600;
  margin: 2px 0;
  width: 100%;
  margin-left: 20px;
}

//for bootstrap container
.fluid-div {
  width: 100%;
  padding: 0;
  position: relative;
}
.recovery-text {
  font-size: 11px;
  color: #677789;
}

.red {
  background-color: #e2443e;
}
.green {
  background-color: #3aaa30;
}
.orange {
  background-color: #ffb972;
}
.purple {
  background-color: #8c6c9e;
}
.statusRectangle {
  width: 8px;
  height: 40px;
  margin-right: 9px;
}

.centerZone {
  display: flex;
}

.row-text {
  vertical-align: middle;
  text-align: start;
}

.zoneColumn.pb-0 {
  padding-left: 0;
}

.priorityColor {
  .red-circle {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: #e2443e;
  }
}
.priorityColor > div {
  display: flex;
  align-items: center;
}
.in-progress {
  color: #3aaa30;
  font-style: italic;
}
.in-progress-truck {
  color: #707070;
  font-style: italic;
}

.mm-cursor-pointer {
  cursor: pointer;
}

.alert-modal {
  max-width: 35vw;
}

@media screen and (max-width: 1000px) {
  .alert-modal {
    max-width: 60vw;
  }
}

@media screen and (max-width: 600px) {
  .alert-modal {
    max-width: 80vw;
  }
}

.no-word-break {
  white-space: nowrap;
}

.kpiDashboard {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
}

@media (min-width: 1620px) {
  .kpiDashboard {
    gap: 0.5rem;
  }
}
@media (min-width: 1240px) and (max-width: 1620px) {
  .kpiDashboard {
    gap: 0.25rem;
  }
}
@media (max-width: 990px) {
  .kpiDashboard {
    justify-content: space-around;
  }
}

.bin_text-area-inside {
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  background-color: white;
  font-weight: bolder;
  color: black;
  width: 400px;
  height: 300px;

  &:focus {
    border: 1px solid #21b291 !important;
    outline: none;
  }
}

.popup-tip-container_start_popup::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  /* border: 2px solid #92D5DB; */
}

.history-flyer-container {
  background-color: #f2f2f2;
  height: auto;
  border: 2px solid grey;
  border-radius: 8px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 15px;
}

.history-grid-container {
  height: 100%;
}

.flyer-toggle-button {
  background: none;
  border: none;
  position: absolute;
  right: 2px;
  top: 2px;
}

.history-header-dropdown {
  width: 245px; /* required to show */
}

.dateGroup {
  letter-spacing: 0; /* No extra letter spacing */
  color: #ffffff; /* Text color set to white */
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 1rem !important;

  font-weight: normal !important;
  font-style: normal !important;
  font-family: 'Open Sans', sans-serif !important;

  top: 289px;
  left: 440px;
  width: 100px;
  height: 24px;

  background: #2b2d2f 0% 0% no-repeat padding-box;
  border-radius: 15px;
}

.MuiTableCell-root {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 12px !important;
  line-height: 18px !important;
  letter-spacing: 0 !important;
  color: #000000 !important;
  text-align: left !important;
}

.flyer-component-title {
  margin-bottom: 5%;
  color: black;
  font-weight: 700;
}

.horizontal-separator {
  margin-top: 5%;
  color: #ccc;
}

.scrollable-div {
  height: auto;
  max-height: 75vh;
  overflow: auto;
  border: 2px solid grey;
  border-radius: 8px;
}

.flyerBottomtextHr {
  border: 1px solid #e2e2e2;
}

.dateButtonClass {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 82px;
  border-radius: 15px !important;
}

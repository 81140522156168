.kpiDashboard {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 15px;
}

.shiftLoaderCard,
.preStartChecksCard {
  width: 21rem !important;
}
.materialMovementsCard {
  width: 100% !important;
}
@media (min-width: 1600px) {
  .materialMovementsCard {
    flex: 1;
  }
}

@media (min-width: 1750px) {
  .expanedSpeedingCard {
    flex: 1;
  }
  .expanedParkedTimeCard {
    flex: 1;
  }
}

@media (max-width: 1750px) {
  .expanedSpeedingCard {
    width: 100% !important;
  }
  .expanedParkedTimeCard {
    width: 100% !important;
  }
  .speedingCard {
    width: 100% !important;
  }
  .parkedTimeCard {
    width: 100% !important;
  }
}

@media (min-width: 1600px) and (max-width: 1750px) {
  .materialMovementChart {
    width: 66% !important;
  }
  .materialMovementDetail {
    width: 30% !important;
  }
}

@media (min-width: 1750px) and (max-width: 1900px) {
  .materialMovementChart {
    width: 70% !important;
  }
  .materialMovementDetail {
    width: 26% !important;
  }
}

@media (min-width: 1800px) {
  .speedingCard {
    width: 56% !important;
  }
  .parkedTimeCard {
    flex: 1;
  }

  .binsCard {
    width: 36% !important;
  }
  .dustControlCard {
    /* width: 63% !important; */
    flex: 1;
  }
}

@media (min-width: 1800px) and (max-width: 1860px) {
  .speedingCard {
    width: 54% !important;
  }
  .parkedTimeCard {
    flex: 1;
  }

  .binsCard {
    width: 33% !important;
  }
  .dustControlCard {
    flex: 1;
  }
}

@media (min-width: 1500px) and (max-width: 1800px) {
  .binsCard {
    width: 41% !important;
  }
  .dustControlCard {
    flex: 1;
  }
}

@media (max-width: 1550px) {
  .speedingCard,
  .parkedTimeCard,
  .binsCard,
  .dustControlCard {
    width: 100% !important;
  }
}

.timeFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.buttonClass {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 82px;
  border-radius: 15px !important;
}
.kpiDashboardCards {
  height: calc(100vh - 101px);
  overflow: auto;
}

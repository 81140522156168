.modal-view {
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #70707066; /* Black w/ opacity */
  z-index: 999;
  display: flex;
}

.modal-content-view {
  border-radius: 14px;
  background-color: white;
  display: flex;
  margin: auto;
  flex-direction: column;
}

.close-icon-view {
  align-self: flex-end;
  padding: 1vh;
  cursor: pointer;
}

.close-icon {
  color: white;
  background-color: #2a2c79;
}

// timer modal

.timer-modal {
  height: 250px;
  width: 38vw;
  max-width: 350px;
  min-width: 300px;
  margin-top: -10px;
  z-index: 10;
}

.css-1pcexqc-container {
  border: 1px solid #ebebeb;
  position: absolute !important;
  background-color: #fff;
  top: -55px !important;
  left: 2px;
  width: 92% !important;
}

.toggle {
  padding: 0 12px;
  margin-left: -3px;
  cursor: pointer;
}

.default-timers {
  background-color: #2d3f5a;
  color: #fff;
  width: 50px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

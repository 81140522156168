.mm-points-table input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #ebebeb !important;
}

.map-area {
  height: calc(100vh - 5rem);
}

.mm-custom-cursor {
  cursor:
    url('https://img.icons8.com/material-two-tone/24/pencil.png') 2 22,
    auto !important;
  /* cursor: url("./pencil.png"); */
}

.mm-ellipse_dropdown_button {
  cursor: pointer;
}
.mm-ellipse_dropdown ul {
  position: fixed;
  border: 1px solid #ebebeb;
  background: #ffffff;
  box-shadow: 1px 3px 4px #00000029;
  border-radius: 4px;
  padding: 10px 20px;
  left: calc(200px + 30rem);
  z-index: 100;
  min-width: 11rem;
  padding: 1rem;
}

#my-tooltip-data-html {
  width: max-content !important;
}

.mm-ellipse_dropdown ul li {
  cursor: 'pointer';
  font-size: 0.8rem;
  font-family: 'Open Sans';
  letter-spacing: '0px';
  color: '#2B2D2F';
  opacity: '1';
  display: 'flex';
  align-items: 'center';
}

.mm-ellipse_dropdown ul li:hover {
  background-color: #ebebeb;
}

.mm-ellipse_dropdown ul li:nth-child(1):hover {
  background-color: #ebebeb;
  border-radius: 0.8rem 0.8rem 0 0;
}

.mm-ellipse_dropdown ul li:last-child:hover {
  background-color: #ebebeb;
  border-radius: 0 0 0.8rem 0.8rem;
}

.detail-popup {
  background-color: white;
  border-radius: 0.7rem;
  opacity: 1;
  padding: 0.3rem;
  min-width: 15rem;
  font-size: 0.8rem;
  margin-left: 0px;
  margin-top: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.extra-detail-popup {
  background-color: white;
  border-radius: 0.7rem;
  opacity: 1;
  padding: 0.3rem;
  min-width: 15rem;
  font-size: 0.8rem;
  margin-left: 0px;
  margin-top: 5px;
  position: absolute;
  bottom: 0;
  left: 120px;
}

.title-popup {
  background-color: #dde5f3;
  color: #000;
  border-radius: 0.4rem;
  opacity: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  min-width: 7rem;
  font-size: 0.8rem;
  margin-left: 0px;
  margin-top: 5px;
  z-index: 999;
  position: absolute;
  bottom: 0;
  left: 0;
}

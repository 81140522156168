.bin-legend-pie-text {
  color: #6a6a6a;
  font-weight: 500;
  margin: 5px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.legend-value,
.bin-legend-pie-bold-text {
  color: #000000;
  width: 50px;
  font: normal normal bold 12px/8px Open Sans;
}

.legend-container {
  width: 100%;
}
.legend-cycle-key {
  font: normal normal bold 12px/8px Open Sans;
  color: #000000;
}
.legend {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.legend-div-scrollable {
  overflow-y: scroll;
}

.legend-div,
.legend-div-scrollable {
  height: 8em;
  margin-top: 3%;
}

.legend-key {
  font: normal normal 600 12px/8px Open Sans;
  color: #6a6a6a;
}

.hoffset {
  margin-left: -33px;
}

.CircularProgressbar-path {
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.6));
}

.CircularProgressbar {
  width: 160px;
}

.CircularProgressbar-text {
  font-family: Open Sans;
  font-weight: 500;
  color: #1a1a1a;
}
